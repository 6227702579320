import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./hr-styles.css";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import MaterialTable from "material-table";
import { getEmployeeData, getEmployeeDataWithStatus, getFine, addFine, updateFine, deleteFine } from "./HRDetails.actions";
import Swal from 'sweetalert';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";


const Fine = (props) => {

    const [employeeId, setEmployeeId] = useState('');
    const [reason, setReason] = useState('');
    const [amountDeduct, setAmountDeduct] = useState('');
    const [date, setDate] = useState('');

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    /* const [state, setState] = React.useState({
         columns: [
             { title: 'Employee Id', field: 'employee_id', render: rowData => appendNonSignificantZeros(rowData.employee_id)   },
             { title: 'Employee', field: 'employee.name' },
             { title: 'Reason', field: 'reason_fine' },
             { title: 'Amount Deducted', field: 'amount_deduct' },
             { title: 'Date', field: 'date' },
         ],
     });*/
    // const handleChangeLateTime = (selectedOption) => {
    //     setLateTime(selectedOption.value);
    // };



    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
    };
    const fineData = props.fine;

    // console.log('props.fine[0]', props.fine[0].employee.name)
    // const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => { let data = { value: x.employeeId,  label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}`  }; return data })

    const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}-${x.designation.designationName}` }; return data })

    const clearStates = () => {
        setEmployeeId('');
        setReason('');
        setAmountDeduct('');
        setDate('');
    }
    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    function saveFine() {
        props.addFine({
            employee_id: employeeId,
            reason_fine: reason,
            amount_deduct: amountDeduct,
            date: date,
        }, handleVisible, handleDangerVisible);




    };

    useEffect(() => {
        // props.getEmployeeData();
        props.getEmployeeDataWithStatus(1);

        // props.getFine();
    }, []);
    return (
        <>
            <Container fluid>
                {props.isFetchingEmployeeData || props.isAddingFine ?
                    <div className="loader-div">
                        <Button variant="info" disabled>
                            <Spinner
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="info"
                            />
                            {props.isAddingFine ? 'Adding Fine...' : 'Fetching Employee...'}

                        </Button>
                    </div> :
                    <Container fluid>
                        <div className="mainHr">
                            <Row>
                                <Col lg="12" md="12" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Select Employee:</b></Form.Label>
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Select Employee..."
                                            onChange={handleChangeEmployee}
                                            options={getEmployeeList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6" md="6" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Reason for Fine/Penalty :</b></Form.Label>
                                        <Form.Control type="text" id="reasonPenalty" placeholder="Enter Reason..." onBlur={(e) => setReason((e.target.value).toUpperCase())} />
                                    </Form.Group>
                                </Col>
                                <Col lg="3" md="3" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Amount for Deduction :</b></Form.Label>
                                        <Form.Control type="number" id="amountDeduction" placeholder="Enter Amount..." onBlur={(e) => setAmountDeduct(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                {/* <Col xl="3" lg="3" md="3" sm="3">
                                    <Form.Group>
                                        <Form.Label>
                                            <b> Select Month </b>

                                        </Form.Label>

                                        <LocalizationProvider dateAdapter={AdapterMoment}>

                                            <DatePicker
                                                // disableFuture
                                                // label="Select Month"
                                                openTo="month"
                                                inputFormat="YYYY-MM"

                                                views={['year', 'month']}
                                                value={date}
                                                onChange={(e) => (console.dir(e), setDate(moment(e).format("YYYY-MM")))}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Form.Group>
                                </Col> */}

                                <Col lg="3" md="3" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Date :</b></Form.Label>
                                        <Form.Control type="date" id="date" min={moment().subtract(1, 'months').format('YYYY-MM-DD')} max={moment().subtract(0, 'months').format('YYYY-MM-DD')} placeholder="Enter Amount..." onBlur={(e) => setDate(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="sendDiv">
                            {((employeeId === '') || (reason === '') || (amountDeduct === '') || (date === ''))?
                                <Button className="sendButton" style={{backgroundColor:'black'}} onClick={saveFine}
                                    disabled
                                >Save</Button>:
                                  <Button className="sendButton" onClick={saveFine}
                               
                                >Save</Button>}
                            </div>
                        </div>
                    </Container>
                }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEmployeeData: () => dispatch(getEmployeeData()),
        addFine: (data, handleVisible, handleDangerVisible) => dispatch(addFine(data, handleVisible, handleDangerVisible)),
        getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    // employeeList: state.HrReducer.employeeList,
    // isAddingSalary: state.HrReducer.isAddingSalary,
    isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
    fine: state.HrReducer.fine,
    isFetchingFineData: state.HrReducer.isFetchingFineData,
    isAddingFine: state.HrReducer.isAddingFine,

    activeEmployeeList: state.HrReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Fine);