import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter } from "lodash";
import {
  getLTAccount,
  getEmployeeData,
  addLTAccount,
  getEmployeeDataWithStatus,
  getEmployeeDataById,
  getEmployeeLeavesDataById,
  addEmployeeLeave,
} from "./HRDetails.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from "sweetalert";
import { Redirect, Link } from "react-router-dom";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
const LeaveTakenOrNot = (props) => {
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [taken, setTaken] = useState();
  const [takenLabel, setTakenLabel] = useState("");
  const [leaves, setLeaves] = useState("");
  const [date, setDate] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = () => {
    setAlertVisible(true)
    // clearStates();
    setTimeout(() => {
        setAlertVisible(false)
    }, 9000);
    Swal({
        title: "Success",
        text: "Added successfully...",
        icon: "success",
        button: "Ok",
    });
}

const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
        setDangerVisibleAlert(false)
    }, 9000);
    Swal({
        title: "Error",
        text: "Something went wrong...",
        icon: "error",
        button: "Ok",
    });
}
  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}-${x.designation.designationName}` }; return data })
  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setEmployeeName(selectedOption.label);
    props.getEmployeeLeavesDataById(selectedOption.value);
    console.log(selectedOption.value, selectedOption.label, "dddd");
  };
  const TakenList = [
    { value: 1, label: "Taken" },
    { value: 0, label: "Not Taken" },
];
const handleTaken = (selectedOption) => {
  setTaken(selectedOption.value)
  setTakenLabel(selectedOption.label)
};
function uploadDivision() {
  props.addEmployeeLeaves({
    allow_leaves: leaves,
    month:date,
    employeeId: employeeId,
    taken: taken,
  }, handleVisible, handleDangerVisible);
  setEmployeeId("")
  props.getEmployeeDataWithStatus(1)
}
console.log(props.employeeLeaveData,"props.props.employeeLeaveData")

  useEffect(() => {
    //props.getLTAccount();
    // props.getEmployeeData();
      props.getEmployeeDataWithStatus(1);
  }, []);

  return (
    <>
      {props.isFetchingActiveEmployeeData||props.isFetchingEmployeeLeavesData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <div style={{ padding: "1% 0" }}>
                <Row>
                <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group>
                <Form.Label>
                  <b> Select Month </b>
                </Form.Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // disableFuture
                    // label="Select Month"
                    openTo="month"
                    inputFormat="YYYY-MM"
                    views={["year", "month"]}
                    value={date}
                    onChange={(newVal) => (
                      console.dir(newVal),
                      setDate(moment(newVal).format("YYYY-MM"))
                    )}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* </Form.Control> */}
              </Form.Group>
            </Col>
                  <Col xl="6" lg="6" md="6" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Employee</Form.Label>
                      <Select
                        value={{label:employeeName}}
                        placeholder="Select Employee..."
                        onChange={handleChangeEmployee}
                        options={getEmployeeList}
                      />
                    </Form.Group>
                  </Col>
              
                </Row>
                <Row>
                  {isEmpty(props.employeeLeaveData)?
                <Col xl="6" lg="6" md="6" sm="6">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Enter Leaves:</b></Form.Label>
                                        <Form.Control type="number" id="EnterLeaves" placeholder="Enter Leavese...." onChange={(e) => setLeaves(e.target.value)} />
                                    </Form.Group>
                                </Col>:
                                  <Col xl="3" lg="3" md="3" sm="3">
                                  <Form.Group className="mb-3" controlId="garana" >
                                      <Form.Label><b>Allowd Leaves:</b></Form.Label>
                                      <Form.Control type="number" id="EnterLeaves" placeholder="Enter Leavese...." disabled value={props.employeeLeaveData[0].allow_leaves} onChange={(e) => setLeaves(e.target.value)} />
                                  </Form.Group>
                              </Col>          
                                }
                           {!isEmpty(props.employeeLeaveData)&&
                                <Col xl="3" lg="3" md="3" sm="3">
                                  <Form.Group className="mb-3" controlId="garana" >
                                      <Form.Label><b>Total Leaves:</b></Form.Label>
                                      <Form.Control type="number" id="EnterLeaves" placeholder="Enter Leavese...." disabled value={props.employeeLeaveData[0].total_leaves} onChange={(e) => setLeaves(e.target.value)} />
                                  </Form.Group>
                              </Col> 
                           }   
                           {!isEmpty(props.employeeLeaveData)&&  
                <Col xl="6" lg="6" md="6" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Taken/Not Taken</Form.Label>
                      <Select
                        //value={name}
                        placeholder="Select Taken/Not Taken..."
                        onChange={handleTaken}
                        options={TakenList}
                      />
                    </Form.Group>
                  </Col>}
                </Row>
              </div>
              <div className="sendDiv">
                        {employeeId==""||!isEmpty(props.employeeLeaveData)&&takenLabel==""||isEmpty(props.employeeLeaveData)&&leaves==""?
                            <Button className="sendButton" style={{backgroundColor:"Black"}} disabled>Save</Button>:
                            <Button className="sendButton" onClick={() => { uploadDivision(); }} >Save</Button>}
                        </div>
              {/* <Row>
                <Col lg="12" sm="12" className="text-center">
                  {employeeId === "" ? (
                    <Button
                      className="sendButtonVoucher"
                      disabled
                      style={{ backgroundColor: "black" }}
                      size="large"
                    >
                      Edit Employee
                    </Button>
                  ) : props.isFetchingEmployeeDataById ? (
                    <Button
                      className="sendButtonVoucher"
                      disabled
                      style={{ backgroundColor: "black" }}
                      size="large"
                    >
                      Fetching Employee Data
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: "/user/edit-employee",
                        state: props.employeeListById,
                      }}
                    >
                      {console.log(props.employeeListById, "empdatatest")}
                      <Button className="sendButtonVoucher">
                        Edit Employee
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row> */}
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    getEmployeeLeavesDataById: (id) => dispatch(getEmployeeLeavesDataById(id)),
    addEmployeeLeaves:(data,handleVisible,handleDangerVisible)=>dispatch(addEmployeeLeave(data,handleVisible,handleDangerVisible))
    
  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeLeaveData:state.HrReducer.employeeLeaveData,
  isFetchingEmployeeLeavesData:state.HrReducer.isFetchingEmployeeLeavesData,
  ltAccount: state.HrReducer.ltAccount,
  isFetchingLTAccount: state.accountsLevelReducer.isFetchingLTAccount,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
  employeeListById: state.HrReducer.employeeListById,
  isFetchingEmployeeDataById: state.HrReducer.isFetchingEmployeeDataById,
  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveTakenOrNot);
