import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { isEmpty, find, forEach, get } from "lodash";
import Select from 'react-select';
import { getEmployeeData } from '../AddDetails/HRDetails.actions';
import moment from 'moment';
import logo from "../../../../../../src/Images/logo.png";

function AppointmentLetter(props) {
  const [employee, setEmployee] = useState([]);
  const [item, setItem] = useState("");
  const getEmployeeList = () => {
    let dt = [];
    if (!isEmpty(props.employeeList)) {
      props.employeeList.map((x) => {
        dt.push({
          value: x.employeeId,
          label: `${x.name}-${x.designation.designationName}`, ///${x.employee_cnic}
        });
      });
      console.log(dt, "ddddddd");
      dt.push({
        value: 0,
        label: `ALL`, ///${x.employee_cnic}
      });
    }
    return dt;
  };
  console.log(getEmployeeList(), "test");
  const handleChangeEmployee = (selectedOption) => {
    setItem(selectedOption.value);
    let empId = selectedOption.value;
    console.log(empId, "test12");
    let getEmp = [];
    if (selectedOption.value == 0) {
      getEmp = props.employeeList;
    } else {
      getEmp = !isEmpty(props.employeeList) && [
        find(props.employeeList, (x) => x.employeeId == empId),
      ];
    }
    setEmployee(getEmp);
    console.log(getEmp, "test1");
  };
  function DownloadSchedulePDF(emp1) {
    console.log(emp1, "aa")
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 50;
    const marginLeft1 = 200;
    const marginLeft2 = 100;
    const marginLeft3 = 300;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);
    console.log(emp1, "emp1");
    forEach(emp1, (dt, idex) => {
      const date = `Date : ${moment(dt.createdAt).format("DD-MM-YY")}`;
      const title = "JAYSON SPARK ENTERPRISES";
      console.log(emp1, "emp1");
      console.log(dt, "dt");
      console.log(idex, "idex");

      const companyName = "HADI TOWN, DEFENCE ROAD SIALKOT 052-3572070";
      const empCode = `Code : ${dt.employeeId}`;
      const empName = `Name : ${dt.name}`;
      const dept = `Department : ${dt.department.departmentName}`;
      const desig = `Designation : ${dt.designation.designationName}`;
      const basic_salary = `Basic Salary : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].basic_salary : 0}`;
      const Medallowance = `Medical Allowances : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].medicial_allowance : 0}`;
      const Accallowance = `Accomodation Allowances : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].accomodation_allowance : 0}`;
      const petrolallowance = `Petrol Allowances : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].petrol_allowance : 0}`;
      const otherallowance = `Other Allowances : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].other_allowance : 0}`;
      const specialallowance = `Special Allowances : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].special_allowance : 0}`;
      const prob_period = `Probation Period : ${dt.probation_period} months`;
      const sub = "Subject : Appointment Letter";
      const add = "Dear Applicant, ";
      const body = `Refer to your application for the Trainee position and subsequently an interview held in our office on\n\n ${dt.joining_date},we are pleased to offer you an appointment as "${dt.designation.designationName}", on the\n\n following terms and conditions:`
      const basic_sal = `Basic Salary : ${(!isEmpty(dt.salaries)) ? dt.salaries[0].basic_salary : 0}`;
      const hra = "HRA :             basic_salary";
      const bonus = `Bonus :          ${(!isEmpty(dt.salaries)) ? dt.salaries[0].bonus : 0}`;
      const all = `Allowance :    ${(!isEmpty(dt.salaries)) ? (Number(dt.salaries[0].medicial_allowance) + Number(dt.salaries[0].petrol_allowance) + Number(dt.salaries[0].special_allowance) + Number(dt.salaries[0].accomodation_allowance) + Number(dt.salaries[0].other_allowance)) : 0}`;
      const body2 = "The salary of the staff is confidential hence you are requested not to reveal the same to others.";
      const hrsig = "(Name & Signature of Authorized Signatory) : _______________________________________________\n\n\n\n\nAcceptance";
      const body3 = "I have read the terms and conditions of this appointment and confirm my acceptance of the same."
      const empsig = "(Signature and Date) : ____________________"


      //const headers = [["Sr No.", "EMPLOYEE'S NAME", "DESCRIPTION", "BILL NO", "DEBIT", "CREDIT", "BALANCE", "DR/CR"]];
      // const data = getData.a.map((x) => [x.voucher_date, x.cross_acc, x.voucher_description, x.voucher_bill_no, isNull(x.debit) ? 0 : x.debit, isNull(x.credit) ? 0 : x.credit, x.accBalance, x.accType == 2 ? "Debit" : x.accType == 1 ? "Credit" : ""])
      doc.text(date, marginLeft, 20, 0, 20)
      doc.addImage(logo, 'PNG', 400+50, 15, 100, 100);

      doc.setFont(undefined, 'bold');
      doc.text(empCode, marginLeft, 70, 0, 20);
      doc.text(empName, marginLeft, 90, 0, 20);
      doc.text(desig, marginLeft, 110, 0, 20);
      doc.text(dept, marginLeft, 130, 0, 20);
      doc.text(basic_salary, marginLeft, 150, 0, 30);
      doc.text(Medallowance, 230, 150, 0, 30);
      doc.text(Accallowance, 400, 150, 0, 30);
      doc.text(petrolallowance, marginLeft, 170, 0, 30);
      doc.text(otherallowance, 230, 170, 0, 30);
      doc.text(specialallowance, 400, 170, 0, 30);
      doc.text(prob_period, marginLeft, 190, 0, 30);
      doc.setFont(undefined, 'bold');
      doc.setFontSize(12);

      doc.text(sub, marginLeft1, 230, 0, 30);
      doc.setLineWidth(1.5)
      doc.line(200, 233, 365, 233);
      doc.setFont(undefined, 'normal')
      doc.setFontSize(10);
      doc.text(add, marginLeft, 280, 0, 30);
      doc.text(body, marginLeft, 310, 0, 30);
      doc.setLineWidth(1);
      doc.setFillColor(0, 0, 0);
      doc.circle(90, 377, 3, 'F');
      doc.text(basic_sal, marginLeft2, 380, 0, 30);

      doc.setLineWidth(1);
      doc.setFillColor(0, 0, 0);
      doc.circle(90, 400, 3, 'F');
      // doc.circle(90, 446, 3, 'F');
      doc.text(all, marginLeft2, 403, 0, 30);

      // doc.setLineWidth(1);
      // doc.setFillColor(0, 0, 0);
      // doc.circle(90, 423, 3, 'F');
      // doc.text(hra, marginLeft2, 426, 0, 30);

      // doc.setLineWidth(1);
      // doc.setFillColor(0, 0, 0);
      // doc.circle(90, 446, 3, 'F');
      // doc.text(bonus, marginLeft2, 449, 0, 30);


      doc.text(body2, marginLeft, 480, 0, 30);
      doc.setFont(undefined, 'bold');
      doc.setFontSize(10);
      doc.text(hrsig, marginLeft, 530, 0, 30);
      doc.setFont(undefined, 'normal');
      doc.setFontSize(10);
      doc.text(body3, marginLeft, 620, 0, 30);
      doc.text(empsig, marginLeft3, 700, 0, 30);
      !(idex === emp1.length - 1) && doc.addPage();
    });
    var string = doc.output('datauristring','dddd'); 
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    var x = window.open('Demand Report');
    x.document.open('Demand Report');
    x.document.write(embed);
    x.document.close();
    // doc.save('demo.pdf');
  };
  useEffect(() => {
    props.getEmployeeData();
  }, [])
  return (
    <>
      <Row style={{ textAlign: 'left' }}>
        <Col lg="6" md="12" sm="12">
          <Form.Group>
            <Form.Label>
              <b> Select Employee </b>
            </Form.Label>
            <Select
              placeholder="Select Employee..."
              onChange={handleChangeEmployee}
              options={getEmployeeList()}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="sendDiv" style={{marginTop:'15px'}}>
        {item==""?
      <Button className="sendButton" disabled style={{backgroundColor:'black'}} onClick={() => DownloadSchedulePDF(employee)}>Generate Appointment Letter</Button>:
        <Button className="sendButton" onClick={() => DownloadSchedulePDF(employee)}>Generate Appointment Letter</Button>}
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    //  getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  // isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentLetter);
