import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import moment from 'moment/moment';
import Select from 'react-select';
import Swal from 'sweetalert';
import { isEmpty, isNull } from "lodash";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { generateSalary, getEmployeeDataWithStatus } from "../AddDetails/HRDetails.actions";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

function CompanySalaryReport(props) {
    const [employeeId, setEmployeeId] = useState('');
    const [allEmployee, setAllEmployee] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const handleAllEmployee = (id) => {
        var All = allEmployee;
        props.employeeList.map((x) => { All.push({ id: x.employeeId }) })
        setAllEmployee(All);
        setEmployeeId(id);
    };
    const handleChangeEmployee = (selectedOption) => {
        selectedOption.value == "All" ? handleAllEmployee(selectedOption.value) : setEmployeeId(selectedOption.value);
        setEmployeeId(selectedOption.value);
    };
    const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}-${x.designation.designationName}` }; return data })
    var getEmployeeUpdatedList = getEmployeeList;
    !isEmpty(getEmployeeList) && getEmployeeUpdatedList.push({ value: "All", label: "All" });

    const handleGenerate = () => {
        props.generateSalary({
            salary_month: moment(startDate).format('YYYY-MM'),
            employeeId: employeeId === "All" ? allEmployee : employeeId
        }, handleVisible, handleDangerVisible);
    };
    const clearStates = () => {

        setEmployeeId('');
        setStartDate('');
        setEndDate('');
    }
    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        // props.getEmployeeData();
        props.getEmployeeDataWithStatus(1);

    }, []);


  return (
            <>
                <Container fluid>
                    <div className="mainHr">
                        <Row>
                            <Col xl="4" lg="4" md="4" sm="4">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Month </b>
    
                                    </Form.Label>
                                    <br/>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
    
                                        <DatePicker
                                            openTo="month"
                                            inputFormat="YYYY-MM"
    
                                            views={['year', 'month']}
                                            value={moment().format("YYYY-MM")}
                                            onChange={(e) => (console.dir(e), setStartDate(moment(e).format("YYYY-MM")))}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    
                                </Form.Group>
                            </Col>
    
                            <Col lg="6" md="6" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Employee:</b></Form.Label>
                                    <Select
                                        isDisabled={isEmpty(getEmployeeList)}
                                        placeholder="Select Employee..."
                                        onChange={handleChangeEmployee}
                                        options={getEmployeeUpdatedList}
                                    />
                                </Form.Group>
                            </Col>
    
                        </Row>
    
                        <div className="sendDiv">
                        {((employeeId === ''))?
                            <Button className="sendButton" style={{backgroundColor:"black"}} onClick={() => handleGenerate()}
                                disabled
                            >Generate</Button>:
                             <Button className="sendButton" onClick={() => handleGenerate()}
                         
                            >Generate</Button>}
                        </div>
                    </div>
                </Container>
            </>
        );
}

const mapDispatchToProps = (dispatch) => {
  return {
        generateSalary: (data, handleVisible, handleDangerVisible) => dispatch(generateSalary(data, handleVisible, handleDangerVisible)),
        getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
  };
};
const mapStateToProps = (state, ownProps) => ({
    activeEmployeeList: state.HrReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySalaryReport);