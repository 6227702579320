import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddInvoice from "./AddInvoice";
import InvoiceList from "./InvoiceList";
import RecivableAccounts from "../RecivableAccounts";
import SelectLevel3Account from "../SelectLevel3Account";
const TotalInvoiceData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 56) ?
                    "AddDepartment" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 57) ?
                        "DepartmentList" : ""} 
                                id="AddDepartment" className="mb-3">
                <Tab eventKey="AddDepartment" title="Add Department"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 56) ? false : true}>  
                    <AddDepartment />
                </Tab>
                <Tab eventKey="DepartmentList" title="Department List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 57) ? false : true}> 
                    <DepartmentList />
                </Tab>
            </Tabs> */}
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 115)
            ? "AddInvoice"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 116)
            ? "InvoiceList"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 119)
            ? "RecivableAccounts"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 120)
            ? "selectLevel3Accounts"
            : ""
        }
        id="Invoice"
      >
        <Tab
          eventKey="AddInvoice"
          title="Add Invoice"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 115)
              ? false
              : true
          }
        >
          <AddInvoice />
        </Tab>
        <Tab
          eventKey="InvoiceList"
          title="Invoice List"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 116)
              ? false
              : true
          }
        >
          <InvoiceList />
        </Tab>
        <Tab
          eventKey="RecivableAccounts"
          title="Sales Account"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 119)
              ? false
              : true
          }
        >
          <RecivableAccounts />
        </Tab>
        <Tab
          eventKey="selectLevel3Accounts"
          title="Level 3 Account"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 120)
              ? false
              : true
          }
        >
          <SelectLevel3Account />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalInvoiceData);
