import Users from "./forms/users/Users";
import ViewUser from "./forms/users/ViewUser";
import Ledger from "../admin_views/forms/ledger/ledger";
import Login from "./accounts/Login";
import AdminDemandDetails from "./AdminDetails/AdminDemandDetails";
import AdminInventory from "./AdminDetails/AdminInventory";
import UserDetailView from "./forms/users/UserDetailView";
import VoucherVerification from "../admin_views/forms/VoucherVerification/VoucherVerification";
import PurchaseOrderVerification from "./AdminDetails/PurchaseOrderVerification";
// import AccountLedger from "./accounts/ledger/Ledger";
import AccountLedger from "../admin_views/accounts/ledger/Ledger";
import UserPermissions from "./forms/Permissions/UserPermissions";
import AddUser from "./forms/users/AddUser";
import AccountConfiguration from "../user_views/forms/adminSetting/AccountConfiguration";

export const dashboardAdminInventoryRoutes = [
  // {
  //   path: "/add_vendors",
  //   name: "Vendors",
  //   icon: "nc-icon nc-badge",
  //   component: Users,
  //   layout: "/admin",
  //   show: true
  // },

  
  // {
  //   path: "/view-user",
  //    name: "View User",
  //    icon: "nc-icon nc-circle-09",
  //    component: ViewUser,
  //   layout: "/admin",
  //   show: true
  // },

  //  {
  //   path: "/Ledger",
  //   name: "Ledger",
  //   icon: "nc-icon nc-circle-09",
  //   component: Ledger,
  //   layout: "/admin",
  //   show: true
  //  },
  // {
  //   path: "/add_inventory",
  //   name: "Inventory",
  //   icon: "nc-icon nc-app",
  //   component: AdminInventory,
  //   layout: "/admin",
  //   show: true
  // },


  {
    path: "/demand-details",
    name: "Demand Verification",
    icon: "nc-icon nc-paper-2",
    component: AdminDemandDetails,
    layout: "/admin",
    show: true,
  },


  {
    path: "/purchase_order_verification",
    name: "Purchase Verification",
    icon: "nc-icon nc-circle-09",
    component: PurchaseOrderVerification,
    layout: "/admin",
    show: true,
  },

  // {
  //   path: "/purchase_order_verification",
  //   name: "Purchase Verification",
  //   icon: "nc-icon nc-circle-09",
  //   component: PurchaseOrderVerification,
  //   layout: "/admin",
  //   show: true,
  // },

  // {
  //   path: "/VoucherVerification",
  //   name: "Voucher Verification",
  //   icon: "nc-icon nc-circle-09",
  //   component: VoucherVerification,
  //   layout: "/admin",
  //   show: true,
  // },
]
export const dashboardAdminAccountRoutes = [

  // {
  //   path: "/add_vendors",
  //    name: "Vendors",
  //    icon: "nc-icon nc-badge",
  //    component: Users,
  //   layout: "/admin",
  //   show: true
  // },

  // {
  //   path: "/add_users",
  //    name: "User Detail View",
  //    icon: "nc-icon nc-badge",
  //    component: UserDetailView,
  //   layout: "/admin",
  //   show: true
  // },
  // // {
  // //   path: "/view-user",
  // //    name: "View User",
  // //    icon: "nc-icon nc-circle-09",
  // //    component: ViewUser,
  // //   layout: "/admin",
  // //   show: true
  // // },

  //  {
  //   path: "/Ledger",
  //   name: "Ledger",
  //   icon: "nc-icon nc-circle-09",
  //   component: Ledger,
  //   layout: "/admin",
  //   show: true
  //  },
  // {
  //   path: "/add_inventory",
  //    name: "Inventory",
  //    icon: "nc-icon nc-app",
  //    component: AdminInventory,
  //   layout: "/admin",
  //   show: true
  // },


  // {
  //   path: "/demand-details",
  //    name: "Demand Details",
  //    icon: "nc-icon nc-paper-2",
  //    component: AdminDemandDetails,
  //   layout: "/admin",
  //   show: true,
  // },

  {
    path: "/VoucherVerification",
    name: "Approve Voucher",
    icon: "nc-icon nc-circle-09",
    component: VoucherVerification,
    layout: "/admin",
    show: true,
  },
  {
    path: "/AccountLedger",
    name: "Ledger",
    icon: "nc-icon nc-circle-09",
    component: AccountLedger,
    layout: "/admin",
    show: true
  },

];

export const adminHRRoutes = [
  {
    path: "/add-user",
    name: "Add User",
    icon: "nc-icon nc-circle-09",
    component: AddUser,
    layout: "/admin",
    show: true
  },
  {
    path: "/add_users",
    name: "User Detail View",
    icon: "nc-icon nc-badge",
    component: UserDetailView,
    layout: "/admin",
    show: true
  },
  {
    path: "/user-permissions",
    name: "User Permissions",
    icon: "nc-icon nc-circle-09",
    component: UserPermissions,
    layout: "/admin",
    show: true
  },

];
export const adminSettingRoutes = [

  {
    path: "/settings",
    name: "Account Configuration",
    icon: "nc-icon nc-circle-09",
    component: AccountConfiguration,
    layout: "/admin",
    show: true
  },

];

