import react, { useState } from "react";
import "jspdf-autotable";
import jsPDF from "jspdf";
import logo from "../../src/Images/logo.png";
import moment from "moment";
import { sumBy,isUndefined } from "lodash";
import swal from "sweetalert";
import { textData } from "./test";
//  const GlobalModal =()=>{

// const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
export const handleVisible = (msg) => {
  // const [visibleAlert, setAlertVisible] = useState(false);
  // setAlertVisible(true);
  // // clearStates();
  // setTimeout(() => {
  //   setAlertVisible(false);
  // }, 9000);
  console.log(msg,"mmmmmmmmm")
  swal({
    title: "Success",
    text: isUndefined(msg)?"Added successfully...":msg,
    icon: "success",
    button: "Ok",
  });
};

export const handleDangerVisible = (errorMsg) => {
  // setDangerVisibleAlert(true);
  // setTimeout(() => {
  //   setDangerVisibleAlert(false);
  // }, 9000);
  swal({
    title: "Error",
    text: isUndefined(errorMsg)?"Something went wrong...":errorMsg,
    icon: "error",
    button: "Ok",
  });
};

export const appendNonSignificantZeros = (id) => {
  if (Number(id) < 10 && Number(id) > 0) {
    return (id = `000${id}`);
  }
  if (Number(id) < 100 && Number(id) >= 10) {
    return (id = `00${id}`);
  }
  if (Number(id) < 1000 && Number(id) <= 100) {
    return (id = `0${id}`);
  }
  if (Number(id) >= 1000) {
    return id;
  }
  return id;
};
export const handleNoDataVisible = () => {
  // setNoDataAlert(true);
  // setTimeout(() => {
  //   setNoDataAlert(false);
  // }, 9000);
  swal({
    title: "Warning",
    text: "No Data Found!",
    icon: "info",
    button: "Ok",
  });
};
export const startFinancialDate = "2022-07-01";
export const endFinancialDate = "2023-06-30";
export function debugBase64(base64URL) {
  var win = window.open();
  if (win)
    win.document.write(
      '<iframe src="' + base64URL + "_blank",
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
}
export function getVoucherName(id) {
  if (id == "1") return "Cash Payment Voucher";
  else if (id == "2") return "Cash Reciept Voucher";
  else if (id == "3") return "Bank Payment Voucher";
  else if (id == "4") return "Bank Reciept Voucher";
  else return "Journal Voucher";
}
export const previewVoucher = (data, status) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "potrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(11);
  const title = "Accounts Ledger";

  doc.addImage(logo, "PNG", 40, 15, 100, 100);
  doc.text(title, marginLeft, 175);
  //

  let yMargin = 190;
  doc.setFontSize(14);
  doc.addFileToVFS('Amiri-Regular.ttf', textData);
  doc.addFont('Amiri-Regular.ttf', 'Amiri-Regular', 'normal');
  doc.setFont('Amiri-Regular');
  doc.setFontSize(20);
// doc.text('میرا نام ہیں', 20, 20);
// doc.text(`${data.voucher_data[0].voucherDescription}`,20,50);
// doc.text('آپ کی عمر کيا ہے؟',50,70,)

  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;

  // doc.setFont("times", "bolditalic");
  doc.setFont('Amiri-Regular');
  doc.text(companyName, 200, 60, 0, 20);
  doc.setFontSize(18);
  doc.text(getVoucherName(data.voucherTypeId), 200, 80, 0, 20);
  doc.setFontSize(14);
  doc.text(`Voucher#${data.voucherManualId}`, 200, 95, 0, 20);
  doc.text(
    `Voucher Status: ${
      status == "Completed"
        ? data.status == "Approve"
          ? "Approved"
          : "Pending"
        : "Pending"
    }`,
    200,
    115,
    0,
    20
  );
  doc.text(
    `Date:${
      status == "Pending"
        ? data.data[0].voucherDate
        : data.voucher_data[0].voucherDate
    }`,
    200,
    135,
    0,
    20
  );
  const headers = [["ACC Code", "Bill#", "Description", "Debit", "Credit"]];
  const headersSigned = [
    ["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"],
  ];
  const tableData =
    status == "Pending"
      ? data.data.map((elt) => [
          elt.accountTittle,
          elt.billNo,
          `${elt.voucherDescription}`,
          elt.accountType !== "1" ? elt.voucherAmount : "",
          elt.accountType == "1" ? elt.voucherAmount : "",
        ])
      : data.voucher_data.map((elt) => [
          elt.accountTittle,
          elt.billNo,
          `${elt.voucherDescription}`,
          elt.accountType !== "1" ? elt.voucherAmount : "",
          elt.accountType == "1" ? elt.voucherAmount : "",
        ]);
  const tableDataAcc =
    status == "Pending"
      ? [
          [
            data.cashAccTittle,
            mergeNames(data.data, "billNo"),
            mergeNames(data.data, "voucherDescription"),
            data.voucherTypeId == "2" || data.voucherTypeId == "4"
              ? sumBy(data.data, "voucherAmount")
              : "",
            data.voucherTypeId == "1" || data.voucherTypeId == "3"
              ? sumBy(data.data, "voucherAmount")
              : "",
          ],
        ]
      : [
          [
            data.cashAccTittle,
            mergeNames(data.voucher_data, "billNo"),
            mergeNames(data.voucher_data, "voucherDescription"),
            data.voucherTypeId == "2" || data.voucherTypeId == "4"
              ? sumBy(data.voucher_data, "voucherAmount")
              : "",
            data.voucherTypeId == "1" || data.voucherTypeId == "3"
              ? sumBy(data.voucher_data, "voucherAmount")
              : "",
          ],
        ];

  let content = {
    startY: 150,
    startX: 5,
    head: headers,
    body: tableData,
    theme: "grid",
    columnStyles: {
      0: { cellWidth: 160 },
      1: { cellWidth: 60 },
      2: { cellWidth: 180 },
      3: { cellWidth: 60 },
      4: { cellWidth: 60 },
    },
    headStyles: {
      fillColor: "#2980ba",
    },
    styles: {
      font: 'Amiri-Regular',
      fontStyle: 'normal',
    }
  };

  doc.autoTable(content);
  // let contentAcc = {
  //     startY: doc.lastAutoTable.finalY + 10,
  //     startX: 5,
  //     head: [],
  //     body: tableDataAcc,
  //     theme: 'grid',
  //     columnStyles: {
  //         0: { cellWidth: 160 },
  //         1: { cellWidth: 60 },
  //         2: { cellWidth: 180 },
  //         3: { cellWidth: 60 },
  //         4: { cellWidth: 60 },

  //     },
  //     headStyles: {
  //         fillColor: '#2980ba'
  //     },
  // };
  // doc.autoTable(contentAcc);
  let contentSign = {
    startY: doc.lastAutoTable.finalY + 10,
    startX: 5,
    head: headersSigned,
    0: { cellWidth: 130 },
    1: { cellWidth: 130 },
    2: { cellWidth: 130 },
    3: { cellWidth: 130 },
    body: [
      [
        { content: "", rowSpan: 5 },
        { content: "", rowSpan: 5 },
        { content: "", rowSpan: 5 },
        { content: "", rowSpan: 5 },
      ],
    ],
    tableWidth: "wrap",
    theme: "grid",
    headStyles: {
      fillColor: "#2980ba",
    },

    bodyStyles: {
      minCellHeight: 300,
    },
  };
  doc.autoTable(contentSign);
  // doc.text('متحرک، صارف دوست ویب ایپلیکیشنز بنانے میں سال کے تجربے کے ساتھ پرجوش رد عمل فرنٹ اینڈ ڈیولپر۔ اور سمیت جدید ویب ٹیکنالوجیز میں ماہر۔ اعلی کارکردگی، ذمہ دار، اور قابل رسائی ڈیجیٹل حل فراہم کرنے کے لیے پرعزم',20,doc.lastAutoTable.finalY+30)
  // doc.text(`Posted at ${moment(new Date()).format("YYYY-MM-DD")} and audited by`, 50, doc.lastAutoTable.finalY + 15, 0, 20);
  // doc.setProperties({ title: "test" });
  //ct doc.output('dataurlnewwindow')
  //oc.save(`Vochouer Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
  // doc.save(`${data.voucherManualId}`);
  doc.setProperties({
    title: `${data.voucherManualId} .pdf`,
  });
  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));

  // var string = doc.output('datauristring','dddd');

  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"

  // var x = window.open('Demand Report');

  // x.document.open('Demand Report');

  // x.document.write(embed);

  // x.document.close();
};

export function mergeNames(array, obj) {
  let output = [];

  for (let item of array) {
    output.push(`[${item[obj]}]`);
  }
  return output.toString();
}
