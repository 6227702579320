import AddWarehouse from "./forms/AddDetails/AddWarehouse";
import AddCategory from "./forms/AddDetails/AddCategory";
import AddSubCategory from "./forms/AddDetails/AddSubCategory";
import Login from "../user_views/accounts/Login";
import { Route, Switch, Redirect } from "react-router-dom";
import UserResponsiveDrawer from "./drawer/ResponsiveDrawer";
import AddInventory from "./forms/AddDetails/AddInventory";
import DemandDetails from "./forms/AddDetails/DemandDetails";
import Attribute from "./forms/AddDetails/Attribute";
import PurchaseOrder from "./forms/AddDetails/PurchaseOrder";
import Vouchers from "./forms/Vouchers/Voucher";
import { connect } from "react-redux";
import { isNull } from "lodash";
import { setValue, setRoute } from "./forms/Vouchers/Voucher.action";
import TrailBalance from "./accounts/trialBalance";
import IGP from "./forms/AddDetails/IGP";
import VoucherSearchDetail from "./forms/Vouchers/VoucherSearchDetail";
import Issuance from "./forms/AddDetails/Issuance";
import Receving from "./forms/AddDetails/Receiving/Receving";
import Ledger from "../admin_views/forms/ledger/ledger";

import AddCustomer from "./forms/production/AddCustomer";
import Levels from "./forms/accountsLevels/Levels";
import AddEmployee from "./forms/HRDetails/AddDetails/AddEmployee";
import ViewEmployees from "./forms/HRDetails/AddDetails/ViewEmployees";

import HRAddDepartment from "./forms/HRDetails/AddDetails/AddDepartment";
import AddDesignation from "./forms/HRDetails/AddDetails/AddDesignation";
import TotalCatalogData from "./forms/production/Catalog/TotalCatalogData";
import Articles from "./forms/production/Articles";

import AddVendors from "./forms/production/AddVendors";
import Order from "./forms/production/Order";
import ProductionDetail from "./forms/production/ProductionDetails/ProductionDetail";
import AddIssuance from "./forms/production/IssuanceFolder/AddIssuance";
import TransactionSummary from "./forms/accountsTransactionSummary/TransactionSummary";
import AddAging from "./forms/accountsAging/AddAging";
import TotalCategoryData from "./forms/AddDetails/TotalCategoryData";
import TotalSubCategoryData from "./forms/AddDetails/TotalSubCategoryData";
import ProfitLoss from "./accounts/ProfitLoss/ProfitLoss";
import AddInventoryDetails from "./forms/AddDetails/AddInventoryDetails";
import AddInventoryStock from "./forms/AddDetails/StockList";
import AddInventoryReport from "./forms/AddDetails/InventoryReport";
import InventoryMaterialTable from "./forms/AddDetails/InventoryMaterialTable";
import ProfitLossDetail from "./accounts/ProfitLoss/ProfitLossDetail";
import BalanceSheetMain from "./forms/BalanceSheet/BalanceSheetMain";
import BalanceSheetDetail from "./forms/BalanceSheet/BalanceSheetDetail";
import level4Tabs from "./forms/accountsLevels/Level4Tabs";
import Level3Tabs from "./forms/accountsLevels/Level3Tabs";
import Level3Tap from "./forms/accountsLevels/Level3Tap";
import Level4Tap from "./forms/accountsLevels/Level4Tap";
import Level5Tabs from "./forms/accountsLevels/Level5Tabs";
import Level5Tap from "./forms/accountsLevels/Level5Tap";
import Level2Tabs from "./forms/accountsLevels/Level2Tabs";
import Level2Tap from "./forms/accountsLevels/Level2Tap";
import AccountLedger from "../user_views/forms/ledger/Ledger";
import LedgerTable from "./forms/ledger/LedgerTable";
import AddDocuments from "./forms/HRDetails/AddDetails/AddDocuments";
import VoucherSearch from "./forms/Vouchers/VoucherSearch";
import VoucherVerification from "../user_views/forms/Vouchers/VoucherVerification";
import TrialBalanceTable from "./accounts/TrialBalanceTable";
import TransactionSummaryTable from "./forms/accountsTransactionSummary/TransactionSummaryTable";
import HRPolicy from "./forms/HRDetails/AddDetails/HRPolicy";
import MaterialLedgerTable from "../admin_views/forms/ledger/LedgerTable";
import Backup from "./forms/HRDetails/AddDetails/Backup";
import EditEmployee from "./forms/HRDetails/AddDetails/EditEmployee";
import AddSalary from "./forms/HRDetails/AddDetails/AddSalary";
import EditSalary from "./forms/HRDetails/AddDetails/EditSalary";
import GenerateSalary from "./forms/HRDetails/AddDetails/GenerateSalary";
import UploadAttendance from "./forms/HRDetails/salary/UploadAttendance";
import salaryManagment from "./forms/HRDetails/SalaryManagment/salaryManagment";
import ShiftControl from "./forms/HRDetails/AddDetails/ShiftControl";
import AddDivision from "./forms/HRDetails/AddDetails/AddDivision";
import EmployeeType from "./forms/HRDetails/AddDetails/EmployeeType";
import Increment from "./forms/HRDetails/salary/Increment";
import Fine from "./forms/HRDetails/AddDetails/Fine";
import HRVoucher from "./forms/HRDetails/AddDetails/HRVoucher";
import HRLedgerTabs from "./forms/HRDetails/AddDetails/HRLedgerTabs";
import MasterControl from "./forms/HRDetails/AddDetails/MasterControl";
import TotalMasterControlDataInventory from "./forms/AddDetails/TotalMasterControlData";
import TotalProductionDetails from "./forms/production/ProductionDetails/totalProductionDetails";
//Marquee Routes
// import Backup from "./forms/HRDetails/AddDetails/Backup";
// import EditEmployee from "./forms/HRDetails/AddDetails/EditEmployee";
// import AddSalary from "./forms/HRDetails/AddDetails/AddSalary";
// import EditSalary from "./forms/HRDetails/AddDetails/EditSalary";
// import GenerateSalary from "./forms/HRDetails/AddDetails/GenerateSalary";
// import UploadAttendance from "./forms/HRDetails/salary/UploadAttendance";
// import salaryManagment from "./forms/HRDetails/SalaryManagment/salaryManagment";
// import ShiftControl from "./forms/HRDetails/AddDetails/ShiftControl";

import TotalMasterControlData from "./forms/MarqueeDetails/AddDetails/TotatMasterControlData";
import TotalHallData from "./forms/MarqueeDetails/AddDetails/TotatHallData";
import TotalEventData from "./forms/MarqueeDetails/AddDetails/TotatEventData";
import TotalBookingData from "./forms/MarqueeDetails/AddDetails/TotatBookingData";
import TotalSubEventData from "./forms/MarqueeDetails/AddDetails/TotatSubEventData";
import TotalMenuData from "./forms/MarqueeDetails/AddDetails/TotatMenuData";
import TotalVisitorMarquee from "./forms/MarqueeDetails/AddDetails/TotatVisitorData";
import TotalCustomerMarquee from "./forms/MarqueeDetails/AddDetails/TotatCustomerData";
import TotalExpenseData from "./forms/MarqueeDetails/AddDetails/TotatExpenseData";
import Dashboard from "./forms/MarqueeDetails/AddDetails/Dashboard";
import AddInstallmentMarquee from "./forms/MarqueeDetails/AddDetails/AddInstallmentMarquee";
import EditBookingMarquee from "./forms/MarqueeDetails/AddDetails/EditBookingMarquee";
// import EmployeeType from "./forms/HRDetails/AddDetails/EmployeeType";
// import Increment from "./forms/HRDetails/salary/Increment";
// import Fine from "./forms/HRDetails/AddDetails/Fine";
// import HRVoucher from "./forms/HRDetails/AddDetails/HRVoucher";
// import HRLedgerTabs from "./forms/HRDetails/AddDetails/HRLedgerTabs";
// import MasterControl from "./forms/HRDetails/AddDetails/MasterControl";
// import HRPolicy from "./forms/HRDetails/AddDetails/HRPolicy";
// import AddDocuments from "./forms/HRDetails/AddDetails/AddDocuments";
// import HRAddDepartment from "./forms/HRDetails/AddDetails/AddDepartment";
// import AddDesignation from "./forms/HRDetails/AddDetails/AddDesignation";
// import AddEmployee from "./forms/HRDetails/AddDetails/AddEmployee";
// import ViewEmployees from "./forms/HRDetails/AddDetails/ViewEmployees";
//End of marquee routes
import { LocalDrinkSharp } from "@material-ui/icons";
import AddBooking from "./forms/MarqueeDetails/AddDetails/AddBooking";
import ReportMarquee from "./forms/MarqueeDetails/AddDetails/ReportMarquee";
import BookingReport from "./forms/MarqueeDetails/AddDetails/BookingReport";
import AccountCashBook from "./forms/accountsCashBook/AccountCashBook";
import ProfitLossStatement from "./accounts/ProfitLoss/ProfitLossStatement";
import UserSelectedAccounts from "./forms/AddDetails/UserSelectedAccounts";
import Adjustment from "./forms/HRDetails/AddDetails/Adjustment";
import InstallmentHistory from "./forms/MarqueeDetails/AddDetails/InstallmentHistory";
import Reports from "./forms/MarqueeDetails/AddDetails/reports";
import profitByBooking from "./forms/MarqueeDetails/AddDetails/profitByBooking";
import monthlyProfit from "./forms/MarqueeDetails/AddDetails/monthlyProfit";
import HRRecivingVoucher from "./forms/HRDetails/AddDetails/HRRecivingVoucher";
import TotalWarehouseData from "./forms/AddDetails/TotalWarehouseData";
import TotalDepartmentData from "./forms/HRDetails/AddDetails/TotalDepartmentData";
import TotalDesignationData from "./forms/HRDetails/AddDetails/TotalDesignationData";
import TotalDivisionData from "./forms/HRDetails/AddDetails/TotalDivisionData";
import TotalEmployeeData from "./forms/HRDetails/AddDetails/TotalEmployeeData";
import TotalShiftControlData from "./forms/HRDetails/AddDetails/TotalShiftControlData";
import TotalEmployeeTypeData from "./forms/HRDetails/AddDetails/TotalEmployeeTypeData";
import TotalSalaryIncrementData from "./forms/HRDetails/salary/TotalSalaryIncrementData";
import TotalFineData from "./forms/HRDetails/AddDetails/TotalFineData";
import TotatInstallmentData from "./forms/MarqueeDetails/AddDetails/TotatInstallmentData";
import TotalInventoryData from "./forms/AddDetails/TotalInventoryData";
import BalanceSheet from "./accounts/BalanceSheet";
import ChartOfAccount from "./accounts/ChartOfAccount";
import AccountsMasterControl from "./forms/AddDetails/AccountsMasterControl";
import TotalInvoiceData from "./forms/AddDetails/Invoice/TotalInvoiceData";
import InstallmentReportTable from "./forms/MarqueeDetails/AddDetails/InstallmentReportTable";
import BookingWiseReportTable from "./forms/MarqueeDetails/AddDetails/BookingWiseReportTable";
import DateWiseReportTable from "./forms/MarqueeDetails/AddDetails/DateWiseReportTable";
import AccountIntegration from "./forms/MarqueeDetails/AddDetails/AccountIntegration";

import TotalSizeData from "./forms/production/Size/TotalSizeData";
import TotalUomData from "./forms/production/Uom/TotalUomData";
import TotalColorData from "./forms/production/Color/TotalColorData";
import TotalBrandData from "./forms/production/Brand/TotalBrandData";
import TotalProductData from "./forms/production/Product/TotalProductData";
import TotalSubProductData from "./forms/production/SubProducts/TotalSubProductData";
import TotalProcessData from "./forms/production/Process/TotalProcessData";
import TotalCustomerData from "./forms/production/Customer/TotalCustomerData";
import TotalDivisionDataProduction from "./forms/production/Division/TotalDivisionData";
import VoucherEdit from "./forms/Vouchers/VoucherEdit";
import ViewVoucher from "./forms/Vouchers/ViewVoucher";
const permissions = JSON.parse(localStorage.getItem("permissions"));
export const dashboardInventoryRoutes = [
  {
    path: "/Add-Warehouse",
    name: "Warehouse",
    icon: "nc-icon nc-bank",
    component: TotalWarehouseData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 1),
    //show: true,
    children: [],
    // routePath: localStorage.setItem("routePath", "/user/Add-Warehouse")
  },
  {
    path: "/Add-Master-Control",
    name: "Master Control",
    icon: "nc-icon nc-bank",
    component: TotalMasterControlDataInventory,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 66),
    // show: true,
    children: [],
    // routePath: localStorage.setItem("routePath", "/user/Add-Warehouse")
  },
  {
    path: "/Add-Category",
    name: "Category",
    icon: "nc-icon nc-notes",
    component: TotalCategoryData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 2),
    //show: true,
    children: [],
  },
  {
    path: "/Add-Sub-Category",
    name: "Sub Category",
    icon: "nc-icon nc-simple-add",
    component: TotalSubCategoryData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 44),
    //show: true,
    children: [],
  },

  {
    path: "/Attribute",
    name: "Attribute",
    icon: "nc-icon nc-puzzle-10",
    component: Attribute,
    layout: "/user",
    show: false,
    children: [],
  },

  {
    path: "/add-inventory",
    name: "Inventory",
    icon: "nc-icon nc-single-copy-04",
    component: TotalInventoryData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 3),
    //show: true,
    children: [],
  },

  // {
  //   path: "/inventory-upload",
  //   name: "Inventory Upload",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: AddInventoryDetails,
  //   layout: "/user",
  //   show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 4),
  //   //show: true,
  //   children: []
  // },

  // {
  //   path: "/inventory-stocklist",
  //   name: "Inventory Stocklist",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: AddInventoryStock,
  //   layout: "/user",
  //   show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 5),
  //   //show: true,
  //   children: []
  // },

  {
    path: "/inventory-List",
    name: "Inventory List",
    icon: "nc-icon nc-single-copy-04",
    component: InventoryMaterialTable,
    layout: "/user",
    show: false,
    children: [],
  },

  // {
  //   path: "/inventory-report",
  //   name: "Inventory Report",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: AddInventoryReport,
  //   layout: "/user",
  //   show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 6),
  //   //show: true,
  //   children: []
  // },

  {
    path: "/add-demand",
    name: "Demand",
    icon: "nc-icon nc-paper-2",
    component: DemandDetails,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 7),
    //show: true,
    children: [],
  },
  {
    path: "/purchase-order",
    name: "Purchase Order",
    icon: "nc-icon nc-backpack",
    component: PurchaseOrder,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 8),
    //show: true,
    children: [],
  },

  {
    path: "/igp",
    name: "IGP",
    icon: "nc-icon nc-backpack",
    component: IGP,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 9),
    // show: true,
    children: [],
  },

  {
    path: "/issuance",
    name: "Issuance",
    icon: "nc-icon nc-backpack",
    component: Issuance,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 10),
    //show: true,
  },
  {
    path: "/receiving",
    name: "GRN",
    icon: "nc-icon nc-circle-09",
    component: Receving,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 45),
    children: [],
  },
  {
    path: "/Ledger",
    name: "Ledger (Inventory)",
    icon: "nc-icon nc-circle-09",
    component: Ledger,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 11),
    //show: true,
    children: [],
  },
  {
    path: "/Material-Ledger-detail",
    name: "Material Ledger Detail",
    icon: "nc-icon nc-circle-09",
    component: MaterialLedgerTable,
    layout: "/user",
    show: false,
    children: [],
  },
];
export const dashboardAccountRoutes = [
  {
    path: "/add-invoice",
    name: "Add Invoice",
    icon: "nc-icon nc-simple-add",
    component: TotalInvoiceData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 65),
    layout: "/user",
    //  show: true,
  },
  {
    path: "/dashboard",
    name: "Current Aging",
    icon: "nc-icon nc-single-copy-04",
    component: UserSelectedAccounts,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 63),
    layout: "/user",
    // show: true,
    children: [],
  },
  {
    path: "/masterControl",
    name: "Admin Control",

    icon: "nc-icon nc-single-copy-04",
    component: AccountsMasterControl,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 64),
    // show: true,
    children: [],
  },
  {
    path: "/Ledger-List",
    name: "Ledger List",
    icon: "nc-icon nc-single-copy-04",
    component: LedgerTable,
    layout: "/user",
    show: false,
    children: [],
  },
  {
    path: "/level4",
    name: "Level 3",
    icon: "nc-icon nc-chart-bar-32",
    component: level4Tabs,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 18),
    //show: true,
    children: [],
  },
  {
    path: "/level5",
    name: "Add Account",
    icon: "nc-icon nc-chart-bar-32",
    component: Level5Tabs,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 19),
    //show: true,
    children: [],
  },
  {
    path: "/voucher",
    name: "Vouchers",
    icon: "nc-icon nc-backpack",
    component: Vouchers,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 12),
    //show: true,
    children: [],
  },
  {
    path: "/VoucherView",
    name: "View Voucher",
    icon: "nc-icon nc-backpack",
    component: ViewVoucher,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 67),
    // show: true,
    children: [],
  },

  {
    path: "/VoucherVerification",
    name: "Approve Voucher",
    icon: "nc-icon nc-backpack",
    component: VoucherVerification,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 14),
    // show: true,
    children: [],
  },
  {
    path: "/voucher-search",
    name: "Search Voucher",
    icon: "nc-icon nc-backpack",
    component: VoucherSearch,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 13),
    //  show: true,
    children: [],
  },

  {
    path: "/voucher-search-detail",
    name: "Search Voucher",
    icon: "nc-icon nc-backpack",
    component: VoucherSearchDetail,
    layout: "/user",
    show: false,
    children: [],
  },
  {
    path: "/edit-voucher",
    name: "Edit Voucher",
    icon: "nc-icon nc-backpack",
    component: VoucherEdit,
    layout: "/user",
    show: false,
    children: [],
  },

  {
    path: "/AccountLedger",
    name: "Ledger",
    icon: "nc-icon nc-backpack",
    component: AccountLedger,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 15),
    //show: true,
    children: [],
  },
  {
    path: "/AccountCashBook",
    name: "Cash Book",
    icon: "nc-icon nc-backpack",
    component: AccountCashBook,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 62),
    //show: true,
    children: [],
  },
  {
    path: "/levels",
    name: "Account Levels",
    icon: "nc-icon nc-backpack",
    component: Levels,
    layout: "/user",
    show: false,
  },

  // {
  //   path: "/level2",
  //   name: "Level 2",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Level2Tabs,
  //   layout: "/user",
  //   //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 17),
  //   show: true,
  //   children: []
  // },
  {
    path: "/level2-details",
    name: "Level 2 Details",
    icon: "nc-icon nc-chart-bar-32",
    component: Level2Tap,
    layout: "/user",
    show: false,
    children: [],
  },
  /*{
    path: "/level3",
    name: "Level 2",
    icon: "nc-icon nc-chart-bar-32",
    component: Level3Tabs,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 18),
    show: true,
    children: []
  },*/
  {
    path: "/level3-details",
    name: "Level 3 Details",
    icon: "nc-icon nc-chart-bar-32",
    component: Level3Tap,
    layout: "/user",
    show: false,
    children: [],
  },
  {
    path: "/level4-details",
    name: "Level 4 Details",
    icon: "nc-icon nc-chart-bar-32",
    component: Level4Tap,
    layout: "/user",
    show: false,
    children: [],
  },

  {
    path: "/level5-details",
    name: "Level 5 Details",
    icon: "nc-icon nc-chart-bar-32",
    component: Level5Tap,
    layout: "/user",
    show: false,
    children: [],
  },
  {
    path: "/trialBalance",
    name: "Trail Balance",
    icon: "nc-icon nc-chart-bar-32",
    component: TrailBalance,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 20),
    //show: true,
    children: [],
  },
  {
    path: "/chartofAccount",
    name: "Chart Of Account",
    icon: "nc-icon nc-chart-bar-32",
    component: ChartOfAccount,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 20),
    //show: true,
    children: [],
  },

  {
    path: "/trialBalanceList",
    name: "Trial Balance List",
    icon: "nc-icon nc-chart-bar-32",
    component: TrialBalanceTable,
    layout: "/user",
    show: false,
    children: [],
  },

  {
    path: "/Profit-Loss-Statement",
    name: "Profit Loss Statement",
    icon: "nc-icon nc-chart-bar-32",
    component: ProfitLossStatement,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 21),
    //show: true,
    children: [],
  },
  {
    path: "/balance-sheet",
    name: "Balance Sheet",
    icon: "nc-icon nc-chart-bar-32",
    component: BalanceSheet,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 21),
    //show: true,
    children: [],
  },
  // {
  //   path: "/Profit-Loss-detail",
  //   name: "Profit Loss Detail",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: ProfitLossDetail,
  //   layout: "/user",
  //   show: false,
  //   children:[]
  // },

  // {
  //   path: "/balance-sheet",
  //   name: "Balance Sheet",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: BalanceSheetMain,
  //   layout: "/user",
  //   show: true,
  //   children:[]
  // },

  // {
  //   path: "/balance-sheet-detail",
  //   name: "Balance Sheet Detail",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: BalanceSheetDetail,
  //   layout: "/user",
  //   show: false,
  //   children:[]
  // },

  {
    path: "/transaction-summary",
    name: "Transaction Summary",
    icon: "nc-icon nc-chart-bar-32",
    component: TransactionSummary,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 23),
    // show: true,
  },

  {
    path: "/transaction-summary-details",
    name: "Transaction Summary Details",
    icon: "nc-icon nc-chart-bar-32",
    component: TransactionSummaryTable,
    layout: "/user",
    show: false,
  },

  {
    path: "/add-aging",
    name: "Aging Report",
    icon: "nc-icon nc-chart-bar-32",
    component: AddAging,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 24),
    // show: true,
  },
  {
    path: "/addCustomer",
    name: "Add Customer",
    icon: "nc-icon nc-simple-add",
    component: TotalCustomerMarquee,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 27),
    show: true,
    children: [],
  },
];
export const dashboardProductionRoutes = [
  {
    path: "/addSize",
    name: "Add Size",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalSizeData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 24),
    show: true,
    children: [],
  },

  {
    path: "/addUOM",
    name: "Add UOM",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalUomData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 25),
    show: true,
    children: [],
  },

  {
    path: "/addColor",
    name: "Add Color",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalColorData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 26),
    show: true,
    children: [],
  },
  {
    path: "/addDivision",
    name: "Add Division",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalDivisionDataProduction,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 26),
    show: true,
    children: [],
  },

  {
    path: "/addCustomer",
    name: "Add Customer",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalCustomerData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 27),
    show: true,
    children: [],
  },

  {
    path: "/addBrand",
    name: "Add Brand",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalBrandData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 28),
    show: true,
    children: [],
  },

  {
    path: "/addProduct",
    name: "Add Product",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalProductData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 29),
    show: true,
    children: [],
  },

  {
    path: "/addSubProduct",
    name: "Add Sub Product",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalSubProductData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 30),
    show: true,
    children: [],
  },

  {
    path: "/addCatalog",
    name: "Catalog",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalCatalogData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 31),
    show: true,
    children: [],
  },

  {
    path: "/addProcess",
    name: "Add Process",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalProcessData,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 32),
    show: true,
    children: [],
  },

  {
    path: "/addVendors",
    name: "Add Vendors",
    icon: "nc-icon nc-chart-bar-32",
    component: AddVendors,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 33),
    show: true,
    children: [],
  },

  {
    path: "/orderCreation",
    name: "Order Creation",
    icon: "nc-icon nc-chart-bar-32",
    component: Order,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 34),
    show: true,
    children: [],
  },

  {
    path: "/add-articles",
    name: "Add Articles",
    icon: "nc-icon nc-chart-bar-32",
    component: Articles,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 35),
    show: true,
    children: [],
  },

  {
    path: "/add-issuance",
    name: "Add Issuance",
    icon: "nc-icon nc-chart-bar-32",
    component: AddIssuance,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 36),
    show: true,
    children: [],
  },
  {
    path: "/production-detail",
    name: "Production Detail",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalProductionDetails,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 37),
    show: true,
    children: [],
  },
];
export const HRRoutes = [
  {
    path: "/add-division",
    name: "Division",
    icon: "nc-icon nc-simple-add",
    component: TotalDivisionData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 25),
    layout: "/user",
    // show: true,
  },

  {
    path: "/add-department",
    name: "Add Department",
    icon: "nc-icon nc-app",
    component: TotalDepartmentData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 26),
    layout: "/user",
    //show: true,
  },

  {
    path: "/add-designation",
    name: "Add Designation",
    icon: "nc-icon nc-badge",
    component: TotalDesignationData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 27),
    layout: "/user",
    //show: true,
  },
  {
    path: "/shift-control",
    name: "Shift Control",
    icon: "nc-icon nc-simple-add",
    component: TotalShiftControlData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 28),
    //show: true,
  },
  {
    path: "/hr-policy",
    name: "Leaves Policy",
    icon: "nc-icon nc-single-copy-04",
    component: HRPolicy,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 30),
    //show: true,
  },
  {
    path: "/employee-type",
    name: "Employee Type",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalEmployeeTypeData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 29),
    layout: "/user",
    //show: true,
  },
  {
    path: "/master-control",
    name: "A/c Integration",
    icon: "nc-icon nc-single-copy-04",
    component: MasterControl,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 39),
    //show: true,
  },
  {
    path: "/add-employee",
    name: "Add Employee",
    icon: "nc-icon nc-circle-09",
    component: TotalEmployeeData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 31),
    layout: "/user",
    // show: true,
  },
  {
    path: "/add-salary",
    name: "Add Salary",
    icon: "nc-icon nc-chart-bar-32",
    component: AddSalary,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 32),
    //show: true,
  },
  // {
  //   path: "/view-employee",
  //   name: "View Employee",
  //   icon: "nc-icon nc-circle-09",
  //   component: ViewEmployees,
  //   layout: "/user",
  //   show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 33),
  //   //show: true,
  // },

  {
    path: "/edit-employee",
    name: "Edit Employee",
    icon: "nc-icon nc-chart-bar-32",
    component: EditEmployee,
    layout: "/user",
    show: false,
  },

  {
    path: "/edit-salary",
    name: "Edit Salary",
    icon: "nc-icon nc-chart-bar-32",
    component: EditSalary,
    layout: "/user",
    show: false,
  },
  {
    path: "/salary-management",
    name: "Joining Document",
    icon: "nc-icon nc-single-copy-04",
    component: salaryManagment,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 38),
    //show: true,
  },

  {
    path: "/salary-increment",
    name: "Salary Increment",
    icon: "nc-icon nc-chart-bar-32",
    component: TotalSalaryIncrementData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 36),
    //show: true,
  },
  {
    path: "/hr-voucher",
    name: "Pay Advances",
    icon: "nc-icon nc-single-copy-04",
    component: HRVoucher,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 40),
    //show: true,
  },
  {
    path: "/hr-reciving-voucher",
    name: "Receive Advances",
    icon: "nc-icon nc-single-copy-04",
    component: HRRecivingVoucher,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 41),
    //show: true,
  },

  {
    path: "/fine-penalties",
    name: "Fine & Penalties",
    icon: "nc-icon nc-single-copy-04",
    component: TotalFineData,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 37),
    //show: true,
  },
  {
    path: "/adjustment",
    name: "Adjustment",
    icon: "nc-icon nc-single-copy-04",
    component: Adjustment,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 43),
    //show: true,
  },
  {
    path: "/hr-ledger",
    name: "Employee Ledger",
    icon: "nc-icon nc-single-copy-04",
    component: HRLedgerTabs,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 42),
    //show: true,
  },
  {
    path: "/upload-attendance",
    name: "Upload Attendance",
    icon: "nc-icon nc-chart-bar-32",
    component: UploadAttendance,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 34),
    //show: true,
  },
  {
    path: "/generate-salary",
    name: "Generate Salary",
    icon: "nc-icon nc-chart-bar-32",
    component: GenerateSalary,
    layout: "/user",
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 35),
    //show: true,
  },
];
export const MarqueeRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-simple-add",
    component: Dashboard,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 46),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-master-controls",
    name: "Master Controls",
    icon: "nc-icon nc-simple-add",
    component: TotalMasterControlData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 57),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-customer-marquee",
    name: "Customer",
    icon: "nc-icon nc-simple-add",
    component: TotalCustomerMarquee,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 55),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-hall",
    name: "Hall",
    icon: "nc-icon nc-simple-add",
    component: TotalHallData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 48),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-menu",
    name: "Menu",
    icon: "nc-icon nc-simple-add",
    component: TotalMenuData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 49),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-event",
    name: "Event",
    icon: "nc-icon nc-simple-add",
    component: TotalEventData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 50),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-sub-event",
    name: "SubEvent",
    icon: "nc-icon nc-simple-add",
    component: TotalSubEventData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 51),
    layout: "/user",
    //show: true,
  },

  {
    path: "/add-expense",
    name: "Expense",
    icon: "nc-icon nc-simple-add",
    component: TotalExpenseData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 52),
    layout: "/user",
    //show: true,
  },

  {
    path: "/add-booking",
    name: "Booking",
    icon: "nc-icon nc-simple-add",
    component: TotalBookingData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 53),
    layout: "/user",
    //show: true,
  },
  // {
  //   path: "/booking-report",
  //   name: "Booking Report",
  //   icon: "nc-icon nc-simple-add",
  //   component: BookingReport,
  //   //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 54),
  //   layout: "/user",
  //   show: true,
  // },

  {
    path: "/add-visitor-marquee",
    name: "Visitors",
    icon: "nc-icon nc-simple-add",
    component: TotalVisitorMarquee,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 56),
    layout: "/user",
    //show: true,
  },
  {
    path: "/add-installment",
    name: "Installment",
    icon: "nc-icon nc-simple-add",
    component: TotatInstallmentData,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 57),
    layout: "/user",
    //show: true,
  },
  {
    path: "/account_integration",
    name: "Account Integration",
    icon: "nc-icon nc-simple-add",
    component: AccountIntegration,
    // show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 57),
    layout: "/user",
    show: true,
  },
  // {
  //   path: "/installment-history",
  //   name: "Installment History",
  //   icon: "nc-icon nc-simple-add",
  //   component: InstallmentHistory,
  //   //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 58),
  //   layout: "/user",
  //   show: true,
  // },
  // {
  //   path: "/edit-booking",
  //   name: "Edit Booking",
  //   icon: "nc-icon nc-simple-add",
  //   component: EditBookingMarquee,
  //   //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 59),
  //   layout: "/user",
  //   show: true,
  // },
  // {
  //   path: "/report",
  //   name: "Expence Report",
  //   icon: "nc-icon nc-simple-add",
  //   component: ReportMarquee,
  //   //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 60),
  //   layout: "/user",
  //   show: true,
  // },
  {
    path: "/reports",
    name: "Report",
    icon: "nc-icon nc-simple-add",
    component: Reports,
    show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 61),
    layout: "/user",
    //show: true,
  },
  {
    path: "/Installment-Report-Table",
    name: "Installment Report",
    icon: "nc-icon nc-simple-add",
    component: InstallmentReportTable,
    layout: "/user",
    // show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 44),
    show: false,
    children: [],
  },
  {
    path: "/Profit-report-by-booking",
    name: "Report",
    icon: "nc-icon nc-simple-add",
    component: BookingWiseReportTable,
    layout: "/user",
    // show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 44),
    show: false,
    children: [],
  },
  {
    path: "/Profit-report-by-date",
    name: "Report",
    icon: "nc-icon nc-simple-add",
    component: DateWiseReportTable,
    layout: "/user",
    // show: !isNull(permissions) && permissions.some((p) => p["cat_id"] === 44),
    show: false,
    children: [],
  },
  // {
  //   path: "/profitbybooking",
  //   name: "Profit By Booking",
  //   icon: "nc-icon nc-simple-add",
  //   component: profitByBooking,
  //   //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 25),
  //   layout: "/user",
  //   show: true,
  // },

  // {
  //   path: "/monthlyprofit",
  //   name: "Monthly Profit",
  //   icon: "nc-icon nc-simple-add",
  //   component: monthlyProfit,
  //   //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 25),
  //   layout: "/user",
  //   show: true,
  // },

  //   {
  //     path: "/add-department",
  //     name: "Add Department",
  //     icon: "nc-icon nc-app",
  //     component: HRAddDepartment,
  //     //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 26),
  //     layout: "/user",
  //     show: true,
  //   },

  //   {
  //     path: "/add-designation",
  //     name: "Add Designation",
  //     icon: "nc-icon nc-badge",
  //     component: AddDesignation,
  //     //show: !isNull(permissions) && permissions.some(p => p['cat_id'] === 27),
  //     layout: "/user",
  //     show: true,
  //   },
  //   {
  //     path: "/shift-control",
  //     name: "Shift Control",
  //     icon: "nc-icon nc-simple-add",
  //     component: ShiftControl,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 28),
  //     show: true,
  //   },
  //   {
  //     path: "/employee-type",
  //     name: "Employee Type",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: EmployeeType,
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 29),
  //     layout: "/user",
  //     show: true,
  //   },
  //   {
  //     path: "/hr-policy",
  //     name: "HR Policy",
  //     icon: "nc-icon nc-single-copy-04",
  //     component: HRPolicy,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 30),
  //     show: true,
  //   },
  //   {
  //     path: "/add-employee",
  //     name: "Add Employee",
  //     icon: "nc-icon nc-simple-add",
  //     component: AddEmployee,
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 31),
  //     layout: "/user",
  //     show: true,
  //   },
  //   {
  //     path: "/add-salary",
  //     name: "Add Salary",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: AddSalary,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 32),
  //     show: true,
  //   },
  //   {
  //     path: "/view-employee",
  //     name: "View Employee",
  //     icon: "nc-icon nc-circle-09",
  //     component: ViewEmployees,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 33),
  //     show: true,
  //   },

  //   {
  //     path: "/upload-attendance",
  //     name: "Upload Attendance",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: UploadAttendance,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 34),
  //     show: true,
  //   },
  //   {
  //     path: "/generate-salary",
  //     name: "Generate Salary",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: GenerateSalary,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 35),
  //     show: true,
  //   },
  //   {
  //     path: "/salary-increment",
  //     name: "Salary Increment",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: Increment,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 36),
  //     show: true,
  //   },
  //   {
  //     path: "/fine-penalties",
  //     name: "Fine & Penalties",
  //     icon: "nc-icon nc-single-copy-04",
  //     component: Fine,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 37),
  //     show: true,
  //   },
  //   {
  //     path: "/edit-employee",
  //     name: "Edit Employee",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: EditEmployee,
  //     layout: "/user",
  //     show: false,
  //   },

  //   {
  //     path: "/edit-salary",
  //     name: "Edit Salary",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: EditSalary,
  //     layout: "/user",
  //     show: false,
  //   },
  //   {
  //     path: "/salary-management",
  //     name: "Salary Management",
  //     icon: "nc-icon nc-single-copy-04",
  //     component: salaryManagment,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 38),
  //     show: true,
  //   },
  //   {
  //     path: "/master-control",
  //     name: "Master Control",
  //     icon: "nc-icon nc-single-copy-04",
  //     component: MasterControl,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 39),
  //     show: true,
  //   },
  //   {
  //     path: "/hr-voucher",
  //     name: "Lt/St Advance",
  //     icon: "nc-icon nc-single-copy-04",
  //     component: HRVoucher,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 40),
  //     show: true,
  //   },
  //   {
  //     path: "/hr-ledger",
  //     name: "HR Ledger",
  //     icon: "nc-icon nc-single-copy-04",
  //     component: HRLedgerTabs,
  //     layout: "/user",
  //     //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 41),
  //     show: true,
  //   },
];

export const BackUp = [
  {
    path: "/backuprestore",
    name: "Backup Restore",
    icon: "nc-icon nc-chart-bar-32",
    component: Backup,
    layout: "/user",
    //show: !isNull(permissions) && permissions.some( p => p['cat_id'] === 46),
    show: true,
  },
];

const mapDispatchToProps = (dispatch) => {
  return {
    setValue: () => dispatch(setValue()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  val: state.VoucherReducer.val,
});
