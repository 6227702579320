import React, { useEffect, useState } from "react";
import { InputGroup, Row, Col, Form, Table, Card, Navbar, Nav, Container, Button, FormGroup, Modal, Alert } from "react-bootstrap";
import "../../admin_views/accounts/style.css";
import { connect } from "react-redux";
import { isEmpty, get, map, find, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import { setModuleKey } from "./account.actions";

const AdminMainPage = (props) => {
    const [userClick, setUserClick] = useState();
    useEffect(() => {
    }, []);
    function Logout() {
        window.localStorage.clear();
        window.location.replace('/login');
    }
    return (
        <><Navbar bg="light" expand="lg">
            <Nav className="ms-auto">
                <Nav.Link
                    className="m-0"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                    <span className="no-icon" onClick={Logout}>Log out</span>
                </Nav.Link>
            </Nav>
        </Navbar>
            <Container fluid>
                <div className="main">
                    <div style={{ position: "absolute" }}>
                        <Row>
                            <Col>
                                <Link to="/admin">
                                    <Card className="bg-light" style={{ width: '14rem' }} onClick={() => {
                                        props.setModuleKey('AdminInventory');
                                        window.localStorage.setItem('module_key', "admin_inventory")

                                    }}>
                                        <Card.Img variant="top" src="https://i.pinimg.com/originals/f1/d1/eb/f1d1ebce94ec4469419df237662a084f.jpg" />
                                        <Card.Body>
                                            <Button>Inventory</Button>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col>
                                <Link to="/admin">
                                    <Card className="bg-light" style={{ width: '14rem' }} onClick={() => 
                                    {
                                    props.setModuleKey('AdminAccounts')
                                    window.localStorage.setItem('module_key', "admin_accounts")
                                  
                                    }}>
                                        <Card.Img variant="top" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_G3dg2acpbAviN1lNX1mYQW_K1UVhfuBkrg&usqp=CAU" />
                                        <Card.Body>
                                            <Button>Account</Button>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col>
                                <Link to="/admin">
                                    <Card className="bg-light" style={{ width: '14rem' }} onClick={() => {props.setModuleKey('AdminHRModules')
                                    window.localStorage.setItem('module_key', "admin_hr")
                                    }}>
                                        <Card.Img variant="top" src="https://ecampusontario.pressbooks.pub/app/uploads/sites/827/2020/08/gears-5459683_1280-1024x1024.png" />
                                        <Card.Body>
                                            <Button>User Modules</Button>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col>
                                <Link to="/admin">
                                    <Card className="bg-light" style={{ width: '14rem' }} onClick={() => {props.setModuleKey('AdminSettingModules')
                                    window.localStorage.setItem('module_key', "admin_setting")
                                    }}>
                                        <Card.Img variant="top" src="https://ecampusontario.pressbooks.pub/app/uploads/sites/827/2020/08/gears-5459683_1280-1024x1024.png" />
                                        <Card.Body>
                                            <Button>Setting</Button>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container></>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        setModuleKey: (data) => dispatch(setModuleKey(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminMainPage);