import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  getEmployeeData,
  GetHRPolicy,
  addHRLeave,
  setCasualLeaves,
  setSickLeaves,
  setOtherLeaves,
  setAnnualLeaves,
  setPaternityLeaves,
  setShortLeaves,
  setSpecialLeaves,
  setMaternityLeaves,
  addMonthlyLeave,
} from "./HRDetails.actions";
import Select from "react-select";
import swal from "sweetalert";

const HRPolicy = (props) => {
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [mleaves, setMLeaves] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    swal({
      title: "Success",
      text: "Leaves Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  useEffect(() => {
    props.GetHRPolicy();
    props.getEmployeeData();
  }, []);
  // const getHRPolicy = props.
  function saveLeave() {
    props.addHRLeave({
      // employee_id: employeeId,     //AddBeforeTesting

      casual_leaves: props.casualLeaves,
      maternity_leaves: props.maternityLeaves,
      sick_leaves: props.sickLeaves,
      other_leaves: props.otherLeaves,
      annual_leaves: props.annualLeaves,
      paternity_leaves: props.paternityLeaves,
      short_leaves: props.shortLeaves,
      special_leaves: props.specialLeaves,
    },
    handleVisible,handleDangerVisible);
  }
  function saveMLeave() {
    props.addMonthlyLeave({
       employee_id: employeeId,  
       relaxation_leaves:mleaves
    },handleVisible,handleDangerVisible);
  }

  // specialLeaves [casualLeave, setCasualLeaves] = useState(isUndefined(props.casualLeaves) ? 0 :props.leaveList[0].casualLeaves );
  //const [casualMaternityLeave, setCasualMaternityLeave] = useState(isUndefined(props.leaveList[0].maternityLeaves) ? 0 :props.leaveList[0].maternityLeaves);

   const getEmployeeList = (!isEmpty(props.employeeList) && (props.employeeList.map((x) => { let data = { value: x.employeeId, label: x.employeeId+" - "+x.name+" - "+x.designation.designationName }; return data })));
  // const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => { let data = { value: x.employeeId, label: x.name }; return data })
  !isEmpty(props.employeeList) &&
  getEmployeeList.unshift({ value: "All", label: "ALL" });
  const handleChangeEmployee = (selectedOption) => {
      setEmployeeId(selectedOption.value);
      setEmployeeName(selectedOption.label);
  };
  return (
    <>
      {props.isFetchingLeaveData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <div>
          {console.log(props, "casualleaves")}

          <Form>
            <p>Monthly Leaves</p>
            <Row>
  
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label><b>Select Employee</b></Form.Label>
                  <Select
                    //value={name}
                    placeholder="Select Employee..."
                    onChange={handleChangeEmployee}
                    options={getEmployeeList}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Monthly Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={mleaves}
                    placeholder="Monthly Leaves...."
                    onChange={(e) => {
                      setMLeaves(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">

              <Button className="sendButton" onClick={saveMLeave}>
                Save
              </Button>
            </div>
            <p>Annual Leaves</p>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Casual Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={props.casualLeaves}
                    placeholder="Allowed Casual Leaves...."
                    onChange={(e) => {
                      props.setCasualLeaves(e.target.value);
                      console.log(props.casualLeaves, "props.casualLeaves");
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Sick Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.sickLeaves}
                    placeholder="Allowed Sick Leaves...."
                    onChange={(e) => props.setSickLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Other Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Allowed Other Leaves...."
                    defaultValue={props.otherLeaves}
                    onChange={(e) => props.setOtherLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Maternity Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.maternityLeaves}
                    placeholder="Allowed Maternity Leaves...."
                    onChange={(e) => props.setMaternityLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Annual Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.annualLeaves}
                    placeholder="Allowed Annual Leaves...."
                    onChange={(e) => props.setAnnualLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Paternity Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.paternityLeaves}
                    placeholder="Allowed Paternity Leaves...."
                    onChange={(e) => props.setPaternityLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Short Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.shortLeaves}
                    placeholder="Allowed Short Leaves...."
                    onChange={(e) => props.setShortLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Special Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.specialLeaves}
                    placeholder="Allowed Special Leaves...."
                    onChange={(e) => props.setSpecialLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button className="sendButton" onClick={saveLeave}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    addHRLeave: (data,handleVisible,handleDangerVisible) => dispatch(addHRLeave(data,handleVisible,handleDangerVisible)),
    addMonthlyLeave:(data,handleVisible,handleDangerVisible)=>dispatch(addMonthlyLeave(data,handleVisible,handleDangerVisible)),
    GetHRPolicy: () => dispatch(GetHRPolicy()),
    setCasualLeaves: (data) => dispatch(setCasualLeaves(data)),
    setSickLeaves: (data) => dispatch(setSickLeaves(data)),
    setOtherLeaves: (data) => dispatch(setOtherLeaves(data)),
    setMaternityLeaves: (data) => dispatch(setMaternityLeaves(data)),
    setAnnualLeaves: (data) => dispatch(setAnnualLeaves(data)),
    setPaternityLeaves: (data) => dispatch(setPaternityLeaves(data)),
    setShortLeaves: (data) => dispatch(setShortLeaves(data)),
    setSpecialLeaves: (data) => dispatch(setSpecialLeaves(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,

  isFetchingLeaveData: state.HrReducer.isFetchingLeaveData,
  maternityLeaves: state.HrReducer.maternityLeaves,
  casualLeaves: state.HrReducer.casualLeaves,
  sickLeaves: state.HrReducer.sickLeaves,
  otherLeaves: state.HrReducer.otherLeaves,
  annualLeaves: state.HrReducer.annualLeaves,
  paternityLeaves: state.HrReducer.paternityLeaves,
  shortLeaves: state.HrReducer.shortLeaves,
  specialLeaves: state.HrReducer.specialLeaves,
});

export default connect(mapStateToProps, mapDispatchToProps)(HRPolicy);
